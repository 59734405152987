@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100..900;1,100..900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --black: #1E1E1E;
  --orange: #DF8E43;
  --white: #E1E1E1;
   }

p, button {

    font-family: "Azeret Mono", monospace;
    font-style: normal;

}
body {
   background-color: var(--black);
}

 .background {
    background:url('/src/assets/bwh_bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover
 }